@font-face {
  font-family: 'Expo-Book';
  src: url('../assets/fonts/ExpoArabic-Book.ttf');
  font-display: block;
}

:root {
  --ion-color-primary: #522c1d;
  --ion-color-primary-rgb: 82, 44, 29;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #48271a;
  --ion-color-primary-tint: #634134;

  --ion-color-secondary: #fb671d;
  --ion-color-secondary-rgb: 251, 103, 29;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #dd5b1a;
  --ion-color-secondary-tint: #fb7634;

  --ion-color-tertiary: #f1ede4;
  --ion-color-tertiary-rgb: 241, 237, 228;
  --ion-color-tertiary-contrast: #522c1d;
  --ion-color-tertiary-contrast-rgb: 82, 44, 29;
  --ion-color-tertiary-shade: #d4d1c9;
  --ion-color-tertiary-tint: #f2efe7;

  --ion-color-quaternary: #f1ede4;
  --ion-color-quaternary-rgb: 241, 237, 228;
  --ion-color-quaternary-contrast: #000000;
  --ion-color-quaternary-contrast-rgb: 0, 0, 0;
  --ion-color-quaternary-shade: #d4d1c9;
  --ion-color-quaternary-tint: #f2efe7;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;

  --ion-font-family: "Expo-Book";

  --ion-color-blue: #4285f4;
  --ion-color-blue-rgb: 66, 133, 244;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #3a75d7;
  --ion-color-blue-tint: #5591f5;

  --ion-color-subscription: #ab520f;
  --ion-color-subscription-rgb: 171, 82, 15;
  --ion-color-subscription-contrast: #ffffff;
  --ion-color-subscription-contrast-rgb: 255, 255, 255;
  --ion-color-subscription-shade: #96480d;
  --ion-color-subscription-tint: #b36327;

  --ion-backdrop-opacity: 0.6;
}

:root[mode=ios] .muli,
:root[mode=md] .muli {
  font-family: 'Expo-Book' !important;
}

.ion-color-blue {
  --ion-color-base: #4285f4;
  --ion-color-base-rgb: 66, 133, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #3a75d7;
  --ion-color-tint: #5591f5;
}

.ion-color-subscription {
  --ion-color-base: #ab520f;
  --ion-color-base-rgb: 171, 82, 15;
  --ion-color-contrast: #f4f5f8;
  --ion-color-contrast-rgb: 244, 245, 248;
  --ion-color-shade: #96480d;
  --ion-color-tint: #b36327;
}

.ion-color-quaternary {
  --ion-color-base: #f1ede4;
  --ion-color-base-rgb: 241, 237, 228;
  --ion-color-contrast: #000000;
  --ion-color-contrast-rgb: 0, 0, 0;
  --ion-color-shade: #d4d1c9;
  --ion-color-tint: #f2efe7;
}

.ion-color-white {
  --ion-color-base: #ffffff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-contrast: #000000;
  --ion-color-contrast-rgb: 0, 0, 0;
  --ion-color-shade: #d4d1c9;
  --ion-color-tint: #f2efe7;
}
