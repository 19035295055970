@import "@ionic/angular/css/core.css";
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/text-alignment.css";

ion-modal:not(.choose-items)::part(content) {
    max-width: 400px !important;
  }
  ion-item.subscription-cart.active[slot='header']>ion-icon.ion-accordion-toggle-icon {
    color: var(--ion-color-subscription);
  }
  .cart-datetime {
    border: 0.5px solid grey;--wheel-fade-background-rgb: rgb(0, 0, 0)
  }
  .popover-wrapper::part(content) {
    color: red !important;
    background: red !important;
    position: absolute;
  }
  ion-datetime ion-button {
    background: var(--ion-color-tertiary);
    color: var(--ion-color-primary);;
    border-radius: 10px;
    &:hover {
      background: var(--ion-color-tertiary-tint);
    }
  }

  #about::part(content) {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  }

  .preorder {
    text-align: center;
    font-weight: 900;
    font-size: 0.7rem;
    background-color: rgba(var(--ion-color-subscription-rgb), 0.8);
    border: 0.5 solid var(--ion-color-subscription);
    color: white;
    position: absolute;
    bottom: 0;
    // left: 50%;
    // transform: translate(-50%);
    width: 100%;
  }

  .big-spinner {
    position: absolute;
  }

  .mySwiper2 .swiper-slide:not(.swiper-slide-thumb-active) {
    filter: brightness(0.70);
  }
  
  .opt-container {
    display: flex !important;
    justify-content: space-around;
    color: var(--ion-color-primary) !important;
    margin-bottom: 20px;
  }
  
  .opt-input {
    background: white;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    width: 51px !important;
    height: 51px !important;
    font-size: 2.5rem !important;
    border-radius: unset !important;
  }
  
  .opt-input:focus-visible {
    outline: unset !important;
    border-bottom: 2px solid var(--ion-color-secondary) !important;
  }
  
  .alerts-design .alert-wrapper .alert-head,
  .alerts-design .alert-wrapper .alert-message,
  .alerts-design .alert-wrapper .alert-input-group .alert-input::placeholder,
  .center-alert>.alert-wrapper,
  .center-modal .alert-wrapper *,
  .center-it,
  .middle-center,
  .middle-center .alert-title {
    text-align: center !important;
  }
  
  .alerts-design.ar .alert-wrapper .alert-input-group .alert-input {
    text-align: right;
  }
  
  .alerts-design.en .alert-wrapper .alert-input-group .alert-input {
    text-align: left;
  }
  
  .rotate .horizontal-wrapper {
    transform: rotate(90deg) !important;
  }
  
  
  .product-second-part {
    flex-direction: column;
  }
  
  .option-col {
    padding-inline-start: 5px;
    ion-button {
      margin-top: -10px;
    }
  }
  
  .option-col>div, .product-second-part, .qty-buttuns {
    display: flex;
    justify-content: space-between;
  }
  
  .product-price {
    color: var(--ion-color-secondary);
  }
  
  .qty-buttuns {
    height: 43px;
  }
  
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal>span {
    background-color: var(--ion-color-primary) !important;
  }
  
  .products-card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .products-card {
    margin: 8px 5px 0 5px;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  @media (max-width: 768px) {
    .products-card {
      width: 100%;
      flex-direction: row;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  
  body * {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  
  .swiper-pagination-progressbar-fill {
    background: var(--ion-color-secondary) !important;
  }
  .qty-nbr {
    width: 30px !important;
    line-height: 40px;
    color: var(--ion-color-primary);
    font-size: 0.9rem;
  }
  .add,
  .remove {
    height: 30px;
    width: 40px !important;
    font-weight: bolder;
    --box-shadow: none !important;
  }
  .add-product {
    height: 30px;
    width: 60px !important;
    font-weight: bolder;
    --box-shadow: none !important;
  }
  
  .login-title {
    font-size: 1.3rem;
  }
  
  #card-element>iframe,
  #card-element, .card-card-element {
    min-height: 190px !important;
  }
  
  .popover-size-option ion-popover::part(content) {
    width: 300px;
  }
  
  .cat-title {
    position: relative;
    margin-bottom: 35px;
  }
  
  .cat-title::after {
    background: url("./assets/icon/underline.svg") no-repeat;
    content: "";
    position: absolute;
    top: 35px;
    left: 50%;
    display: block;
    width: 200px;
    height: 25px;
    transform: translateX(-50%);
  }
  .subscribe + p.indent {
    text-indent: 50px;
  }
  .subscribe {
    display: block;
    border-bottom: 42px solid var(--ion-color-subscription);
    border-left: 24px solid transparent;
    border-right: 42px solid transparent;
    width: 125px;
    height: 0;
    transform: rotate(-45deg);
    position: absolute;
    z-index: 1;
    left: -34px;
    top: -1px;
    font-size: 0.6rem;
    color: white;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
  }
  
  .product-second-part {
    ion-button::part(native) {
      box-shadow: none;
    }
  }
  
  .fab-share-btn::part(native) {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  
  .img-announcement::part(image) {
    box-shadow: 0 0 7px grey !important;
    border-radius: 10px;
  }
  
  .img-cat::part(image) {
    border-radius: 50% !important;
    width: 50px !important;
    height: 50px !important;
  }
  
  .img-cat {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .new-img::part(image) {
    box-shadow: 0 0 7px grey !important;
    border-radius: 10px;
  }
  
  .img-announcement::part(image) {
    border-radius: 0;
  }
  
  ng-otp-input input {
    padding: 0;
  }
  
  .share-location-comp::part(background),
  .share-location-comp::part(content),
  .share-location-comp::part(backdrop) {
    background-color: transparent !important;
  }
  
  .loadProducts-spinner-c {
    margin: 100px auto;
    width: 100%;
    transform: scale(4);
  }
  
  ngx-extended-pdf-viewer .zoom {
    height: 100% !important;
  }
  
  app-checkout.turn {
    display: none !important;
  }
  
  
  ion-datetime {
    &.active {
      --background: rgba(var(--ion-color-subscription-rgb), 0.25);
      --background-rgb: var(--ion-color-subscription-rgb);
    }
  
    &.inactive {
      --background: rgba(var(--ion-color-medium-rgb), 0.25);
      --background-rgb: var(--ion-color-medium-rgb);
    }
  
    border-radius: 16px;
    margin: 0 auto;
  }
  
  @media (min-width: 768px) {
    ion-modal.choose-items::part(content) {
      --width: 80%;
    }
    .products-card {
      max-width: 200px;
      min-height: 300px;
      margin-right: 0;
    }
  
    .product-images {
      // display: block;
      // width: 100%;
    }
  
    .product-description::part(content) {
      min-width: 60% !important;
      --height: 90%
    }
  
    .info-box-titles-container>.info-box-titles, .info-boxes>.infoBox-header, .info-boxes>.infoBox-content {
      font-size: 0.9rem !important;
    }
  
    .delivery-message {
      font-size: 1.2rem !important;
    }
  }
  
  @media only screen and (max-width: 939px) {
    ion-modal.choose-items::part(content) {
      --width: 100%;
    }
  }
  